<template>
    <Modal v-model="show" title="添加用户" :loading="loading" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRules" label-colon :label-width="90">
            <FormItem label="角色" prop="roleIdList">
                <Select v-model="addForm.roleIdList" multiple placeholder="请选择">
                    <Option v-for="item in roleArray" :label="item.roleName" :value="item.roleId"
                            :key="item.roleId"></Option>
                </Select>
            </FormItem>
            <FormItem label="姓名" prop="name">
                <Input v-model="addForm.name" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="联系方式" prop="contactInfo">
                <Input v-model="addForm.contactInfo" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="用户名" prop="userName">
                <Input v-model.trim="addForm.userName" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="密码" prop="password">
                <Input type="password" v-model="addForm.password" placeholder="请输入" clearable/>
            </FormItem>
        </Form>

        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" @click="submit">确定</Button>
        </div>
    </Modal>
</template>
<script>
    import {reqGetRoleList, reqAddUser} from "../../../api/system-api";
    export default {
        name: "addUser",
        props: {
            value: {
                type: Boolean,
                default: false
            },
        },
        data() {
            let checkPhone = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入联系人电话'));
                } else {
                    let regPone = null;
                    let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
                    let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
                    if (value.charAt(0) == 0) {    // charAt查长第一个字符方法，用来判断输入的是座机还是手机号
                        regPone = tel;
                    } else {
                        regPone = mobile;
                    }
                    if (!regPone.test(value)) {
                        return callback(
                            new Error("请填写联系人电话(座机格式'区号-座机号码')")
                        );
                    }
                    callback();
                }
            };
            let checkUserName = (rule, value, callback) => {
                if (value === '' || value.length == 0) {
                    callback(new Error('请输入用户名'));
                } else if (value.length < 6 || value.length >= 20) {
                    callback(new Error('输入的用户名需在6到20个字符之间'));
                } else if (value == /^[a-zA-Z]{1}\w*$/) {
                    callback(new Error('用户名由字母数字下划线组成,且必须以字母开头'));
                }
                else {
                    callback();
                }
            };
            return {
                show: this.value,
                loading: true,
                addForm: {
                    name: '',
                    contactInfo: '',
                    userName: "",
                    password: '',
                    roleIdList: ''
                },
                roleArray: [],
                addFormRules: {
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                        {min: 2, max: 20, message: '姓名不能超过20个字符', trigger: 'blur'},
                    ],
                    userName: [
                        {required: true, validator: checkUserName, trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {
                            pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                            message: '密码由字母和数字组成6到16位',
                            trigger: 'blur'
                        },
                    ],
                    contactInfo: [
                        {required: true, validator: checkPhone, trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            // base64加密开始
            encode64(input) {
                var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                    + "wxyz0123456789+/" + "=";
                var output = "";
                var chr1, chr2, chr3 = "";
                var enc1, enc2, enc3, enc4 = "";
                var i = 0;
                do {
                    chr1 = input.charCodeAt(i++);
                    chr2 = input.charCodeAt(i++);
                    chr3 = input.charCodeAt(i++);
                    enc1 = chr1 >> 2;
                    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                    enc4 = chr3 & 63;
                    if (isNaN(chr2)) {
                        enc3 = enc4 = 64;
                    } else if (isNaN(chr3)) {
                        enc4 = 64;
                    }
                    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                        + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                    chr1 = chr2 = chr3 = "";
                    enc1 = enc2 = enc3 = enc4 = "";
                } while (i < input.length);

                return output;
            },
            submit(){
                this.$refs['addForm'].validate((valid) => {
                    let that = this;
                    if (valid) {
                        this.addForm.password = this.encode64(this.encode64(this.addForm.password) + 'pd');
                        this.closeModal(true);
                        that.loading = true;
                        let para = Object.assign({}, this.addForm);
                        reqAddUser(para).then(res => {
                            if (res.data.code === 1) {
                                this.closeModal(false);
                                this.$emit('getUserList');
                                that.loading = false;
                                that.$Message.success(res.data.msg);
                            } else if (res.data.code === 7) {
                                // this.closeModal(false);
                                that.loading = false;
                                that.$Message.warning(res.data.msg);
                            } else {
                                // this.closeModal(true);
                                that.loading = false;
                                that.$Message.warning(res.data.msg);
                            }
                        }).catch(() => {
                            this.closeModal(false);
                            that.$Message.error('添加失败');
                        })
                    }
                    setTimeout(() => {
                        this.loading = false;
                        this.$nextTick(() => {
                            this.loading = true;
                        });
                    }, 1000);
                });
            },
            //获取全部角色
            getAllRole(){
                reqGetRoleList().then((res) => {
                    this.roleArray = res.data
                })
            },
            closeModal(val) {
                this.$emit('input', val);
            }
        },
        mounted(){
            this.getAllRole();
        },
        watch: {
            value(val){
                this.show = val;
            },
            show(val) {
                //当重新显示增加数据的时候重置整个form表单
                if (val) {
                    this.addForm.roleIdList = [];
                    this.$refs['addForm'].resetFields();
                } else {// 反之则关闭页面
                    this.closeModal(val);
                }
            }
        }
    }
</script>
