<template>
    <Modal v-model="viewUserShow" title="查看用户" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" label-colon :label-width="135">
            <FormItem label="角色" prop="roleName">
                <div>{{viewForm.roleNames}}</div>
            </FormItem>
            <FormItem label="姓名" prop="name">
                <div>{{viewForm.name}}</div>
            </FormItem>
            <FormItem label="部门" >
                <div>{{viewForm.departmentName}}</div>
            </FormItem>
            <FormItem label="联系方式" prop="contactInfo">
                <div>{{viewForm.contactInfo}}</div>
            </FormItem>
            <FormItem label="用户名"  prop="userName">
                <div>{{viewForm.userName}}</div>
            </FormItem>
            <FormItem label="用户状态" prop="status">
                 <div>{{viewForm.status === '0' ? '启用' : '禁用'}}</div>
            </FormItem>
            <FormItem label="默认角色"  prop="defaultRoleName">
                <div>{{viewForm.defaultRoleName}}</div>
            </FormItem>
    	</Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
    import {reqGetUserById} from "../../../api/system-api";
    export default {
        name: "userView",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            id: ''
        },
        data() {
            return {
                viewForm: {
                },
                viewUserShow: this.value
            }
        },
        methods: {
            closeModal(val) {
                this.$emit('input', val);
            },
            //通过id查询
            getUserById(){
                let that=this;
                reqGetUserById({id: that.id}).then(res=>{
                    if (res.data.code === 1) {
                        that.viewForm = res.data.data;
                    } else {
                        that.$Message.error('查询失败');
                    }
                }).catch(() => {
                    that.$Message.error('查询失败');
                })
            }
        },
        mounted() {
        },
        watch: {
            value(val) {
                this.viewUserShow = val;
            },
            viewUserShow(val) {
                //当重新显示增加数据的时候重置整个form表单
                this.viewUserShow = val;
                if (val) {
                    this.$refs['viewForm'].resetFields();
                    this.getUserById();
                } else {// 反之则关闭页面
                    this.closeModal(val);
                }
            }
        },
    }
</script>