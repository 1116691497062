<template>
    <Modal v-model="show" title="编辑用户" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="buttonFormRule" label-colon :label-width="90">
            <FormItem label="角色" prop="roleId">
                <Select v-model="editForm.roleIdList" multiple placeholder="请选择">
                    <Option v-for="item in roleArray" :label="item.roleName" :value="item.roleId"
                            :key="item.roleId"></Option>
                </Select>
            </FormItem>
            <FormItem label="姓名" prop="name">
                <Input v-model="editForm.name" show-word-limit :maxlength="20" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="部门" >
                <Input :disabled=true v-model="editForm.departmentName" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="联系方式" prop="contactInfo">
                <Input v-model="editForm.contactInfo" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="用户名" prop="userName">
                <Input :disabled=true v-model.trim="editForm.userName" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="密码" prop="password">
                <Input type="password" v-model="editForm.password" placeholder="若不输入，则原密码不变" clearable/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="submit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetRoleList, reqEditUserData, reqGetUserById} from "../../../api/system-api";

export default {
    name: "editUser",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: ''
    },
    data() {
        let checkPhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系人电话'));
            } else {
                let regPone = null;
                let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
                let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
                if (value.charAt(0) == 0) {    // charAt查长第一个字符方法，用来判断输入的是座机还是手机号
                    regPone = tel;
                } else {
                    regPone = mobile;
                }
                if (!regPone.test(value)) {
                    return callback(
                        new Error("请填写联系人电话(座机格式'区号-座机号码')")
                    );
                }
                callback();
            }
        };
        return {
            show: false,
            loading: false,
            roleArray: [],
            roleId: '',
            editForm: {
                name: '',
                contactInfo: '',
                userName: '',
                password: '',
                roleIdList: [],
                dRoleId: ''
            },
            buttonFormRule: {
                name: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                ],
                userName: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                ],
                password: [
                    {required: false, message: '请输入密码', trigger: 'blur'},
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                        message: '密码由字母和数字组成6到16位',
                        trigger: 'blur'
                    },
                ],
                contactInfo: [
                    {required: true, validator: checkPhone, trigger: 'blur'},
                ],
            },
        }
    },
    methods: {
        //获取全部角色
        getAllRole() {
            reqGetRoleList().then((res) => {
                this.roleArray = res.data;
                this.roleId = res.data.filter(item => item.roleName === "员工")[0].roleId
                this.roleArray = res.data.filter(item => item.roleName !== "员工")
            })
        },
        // base64加密开始
        encode64(input) {
            let keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                + "wxyz0123456789+/" + "=";
            let output = "";
            let chr1, chr2, chr3 = "";
            let enc1, enc2, enc3, enc4 = "";
            let i = 0;
            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                    + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);

            return output;
        },
        submit() {
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.editForm.roleIdList.push(this.roleId)
                    if(!this.editForm.roleIdList.includes(this.editForm.droleId)){
                        this.editForm.dRoleId = this.roleId
                    }
                    this.editForm.roleNameList = this.roleArray.filter(item => this.editForm.roleIdList.includes(item.roleId)).map(item => item.roleName);
                    if (this.editForm.password) {
                        this.editForm.password = this.encode64(this.encode64(this.editForm.password) + 'pd');
                    }
                    delete this.editForm['createTime'];
                    let para = Object.assign({}, this.editForm)
                    reqEditUserData(para).then(res => {
                        this.loading = false;
                        if (res.data.code == 1) {
                            this.$Message.success(res.data.msg);
                            // 提交表单数据成功则关闭当前的modal框
                            this.closeModal(false);
                            // 同时调用父页面的刷新页面的方法
                            this.$emit('getUserList');
                        } else {
                            let msg = res.data.msg;
                            if (res.data.data) {
                                msg = res.data.data;
                            }
                            this.$Message.error(msg);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error("编辑用户失败");
                    });
                }
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    mounted() {
        this.getAllRole();
    },
    watch: {
        value(val) {
            this.show = val;
        },
        show(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                //通过Id查询用户信息
                reqGetUserById({id: this.id}).then(res => {
                    this.editForm = res.data.data
                });
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
